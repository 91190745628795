
import React, {Fragment} from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Routes, NavLink} from 'react-router-dom';
import { Link } from 'react-router-dom';

import { useEffect, useState } from 'react';

import { useForm, ValidationError } from '@formspree/react';

const Homepage = () => {

  const [state, handleSubmit] = useForm("meojrajp");
  const [isAlertShown, setIsAlertShown] = useState(false);
  
  useEffect(() => {
    if (state.succeeded && !isAlertShown) {
      alert("Thanks for joining!");
      setIsAlertShown(true); // Ensure the alert is shown only once
    }
  }, [state.succeeded, isAlertShown]);

  const products = [
    {
      name: "AstroHoodie",
      image: `${process.env.PUBLIC_URL}/content/astrohoodie1.png`,
      price: "74€"
    },
    {
      name: "Sweatshirt",
      image: `${process.env.PUBLIC_URL}/content/skeleton1.png`,
      price: "66€"
    },
    // {
    //   name: "Pasta",
    //   image: `${process.env.PUBLIC_URL}/content/m1.png`,
    //   price: "45€"
    // },
    // {
    //   name: "Ribs",
    //   image: `${process.env.PUBLIC_URL}/content/r1.png`,
    //   price: "45€"
    // },
    {
      name: "Shorts",
      image: `${process.env.PUBLIC_URL}/content/shorts1.png`,
      price: "40€"
    },
    {
      name: "Pants",
      image: `${process.env.PUBLIC_URL}/content/pants01.png`,
      price: "58€"
    },
    // {
    //   name: "Davide",
    //   image: `${process.env.PUBLIC_URL}/content/davide1.png`,
    //   price: "45€"
    // },
    {
      name: "RedFlag",
      image: `${process.env.PUBLIC_URL}/content/redflag1.png`,
      price: "52€",
    },
    // {
    //   name: "Goat",
    //   image: `${process.env.PUBLIC_URL}/content/goat1.png`,
    //   price: "58€",
    // },
    // {
    //   name: "Shtrupopskii",
    //   image: `${process.env.PUBLIC_URL}/content/shtrupopskii1.png`,
    //   price: "31€",
    // },
    {
      name: "Pavia",
      image: `${process.env.PUBLIC_URL}/content/pavia1.png`,
      price: "68€",
    },
    {
      name: "TheBlind",
      image: `${process.env.PUBLIC_URL}/content/blb1.jpg`,
      price: "66€",
    },
    {
      name: "MadeinVchera",
      image: `${process.env.PUBLIC_URL}/content/miv1.jpg`,
      price: "68€",
    },
    {
      name: "Vest (Coming Soon)",
      image: `${process.env.PUBLIC_URL}/content/vest.jpg`,
      price: "€€",
    },

    // Add more products here
  ];

  return (
  
    <div className="bg-black">
    {/* <div className="bg-[url('/public/bg23.png')]"> */}

      <nav className=" border-gray-200 sm:mb-20 mb-4"> {/* navbar */} 
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <Link to='../.' className="flex items-center space-x-3">
            <img src={`${process.env.PUBLIC_URL}/logo.png`}  className="h-8" alt="Flowbite Logo" />
            {/* <span className="self-center text-2xl font-semibold whitespace-nowrap">Shtrupopskii</span> */}
          </Link>
          <Link to='../checkout' className="flex md:order-2 space-x-3">
            <button
              type="button"
              className="text-black bg-[#dcff51] hover:bg-[#dcff51] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center"
            >
              Cart
            </button>
            
          </Link>
         
        </div>
      </nav>

      <div>  {/* Banner*/}
          <div className="flex justify-center sm:mb-20 mb-10">
            <img 
              src={`${process.env.PUBLIC_URL}/SHTUPOPSKIIbANNER2.png`} 
              alt="banner" 
              className="sm:w-[70%] max-w-[95%]" 
            />
          </div>
      </div>

      <div className='flex justify-center w-full sm:mb-32 mb-10'>
        <div className='w-[70%] text-center'>
          
          
        <h1 className="mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-6xl"><span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">Shtrupopskii</span> marchio di abbigliamento</h1>
        <p className="text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">Streetwear reinventato con t-shirt oversize audaci. Esprimi la tua unicità con stile e sicurezza.</p>


        </div>
      </div>
      <div className='flex justify-center w-full'>
        <div className='grid gap-4 grid-cols-2 items-center justify-center sm:grid-cols-4 sm:max-w-[70%] max-w-full'>
          
          {products.map((product, index) => (
          <Link to={`./products/${product.name}`} key={index} className=''>
            <div>
              <img
                src={product.image}
                alt={product.name}
                className=""
              />
            </div>
            <div className='flex mt-8 justify-between mb-14 text-sm sm:text-base md:text-lg lg:text-xl xl:text-md 2xl:text-md text-white font-mono'>
                <div>{product.name}</div>
                <div>{product.price}</div>
              </div>
          </Link>
        ))}
    
       
          
          {/* <div className='sm:m-6 m-2'>
            <div>
              <img 
                src={`${process.env.PUBLIC_URL}/2.png`} 
                alt="Right Side Image" 
                className="" 
              />
            </div>
            <div className='mt-2 sm:mb-10 mb-2 text-md text-white font-mono'>Shtrupopskii T-Shirt 002</div>
            <div className='flex justify-center w-full sm:scale-100 scale-[0.6]'>
              <stripe-buy-button

                buy-button-id="buy_btn_1PtwDKRthG6u34GZu9OnOmab"
                publishable-key="pk_live_51PqCwZRthG6u34GZplr9dchtXfwO8vAUDDU9h8LcqVRv3lCmEEiTaVgtoMSHqGtmbKI6TiXMaMMlOBEVJ4uwsYSE00ktjJxD8k"
              >
              </stripe-buy-button>
            </div>
          </div> */}
          
          

        </div>
      </div>



      <div className='flex justify-center w-full my-20'>

        <div className='sm:w-[50%] w-[95%]'>
        
          <p className="mb-3 text-gray-500 dark:text-gray-400 first-letter:text-7xl first-letter:font-bold first-letter:text-gray-900 dark:first-letter:text-gray-100 first-letter:me-3 first-letter:float-start">Benvenuti da Shtrupopskii, dove la moda incontra l'innovazione in ogni punto. Il nostro marchio è costruito su una base di creatività, qualità e un impegno per la sostenibilità. Da Shtrupopskii, crediamo che l'abbigliamento sia più di un semplice tessuto: è una dichiarazione, uno stile di vita e una forma d'arte.</p>
          <p className="text-gray-500 dark:text-gray-400">Le nostre collezioni sono progettate con meticolosa attenzione ai dettagli, assicurando che ogni capo non solo abbia un bell'aspetto, ma sia anche incredibilmente piacevole da indossare. Che tu stia cercando design audaci e di tendenza o classici senza tempo, Shtrupopskii offre una moda che rafforza e ispira, il tutto mantenendoti a tuo agio e sicuro in ogni momento.</p>
        </div>
      </div>

<div>
<svg className="w-10 h-10 mx-auto mb-3 text-gray-400 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 14">
        <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z"/>
    </svg>
</div>
   

      <div className="sm:flex contents h-screen font-sans mt-20"> {/* Sign Up Email */}
          {/* Left side */}
          <div className="sm:w-1/2 w-full flex flex-col items-center justify-center bg-black text-white p-8">
            <div className="flex flex-col sm:items-start items-center space-y-10">
              {/* Logo */}
              <div className="text-4xl font-extrabold tracking-wide">
                  <img 
                  src={`${process.env.PUBLIC_URL}/logo.png`} 
                  alt="Right Side Image" 
                  className="w-20 h-auto object-fit" 
                />
              </div>

              {/* Coming soon text */}
              <div className="text-3xl font-bold">
                Stiamo arrivando!!
              </div>
              
              {/* Description */}
              <p className="text-center max-w-md text-lg font-light">
                We are preparing something amazing and exciting for you.
              </p>
              

              {/* Email input & subscribe button in a single line */}
              <form onSubmit={handleSubmit}>
                <input 
                  type="email" 
                  id="email"
                  name="email"
                  placeholder="Inserisci la tua email" 
                  className="flex-grow sm:px-4 px-2 py-2 rounded-l-lg text-black focus:outline-none"
                />
                <ValidationError 
                  prefix="Email" 
                  field="email"
                  errors={state.errors}
                />
                <button className="px-6 py-2 bg-white text-black hover:bg-gray-200 font-semibold rounded-r-lg" type="submit" disabled={state.submitting}>
                 Iscriviti
                </button>
              </form>

              {/* Instagram logo */}
              <div className="text-2xl mt-4">
                <a href="https://www.instagram.com/shtrupopskii/" target="_blank" rel="noopener noreferrer">
                  <img src={`${process.env.PUBLIC_URL}/insta.png`} alt="Instagram" className="w-10 h-10" />
                </a>
              </div>
            <Link to='./info' className='font-mono font-medium'> Terms And Privacy</Link>

            </div>
          </div>

          {/* Right side */}
          <div className="w-1/2 bg-black  justify-end items-center sm:flex hidden">
            <img 
              src={`${process.env.PUBLIC_URL}/astrafatida1quad.png`} 
              alt="Right Side Image" 
              className="h-full object-right object-contain" 
            />
          </div>

      </div>

    </div>
  
  );
};

export default Homepage;

