import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';

const Success = () => {
    return (
      <div className="bg-black text-white min-h-screen flex items-center justify-center">
      <div className='flex flex-col justify-center items-center'>
        <h1 className="mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-6xl text-center">
          <span className="text-transparent bg-clip-text bg-gradient-to-r to-red-600 from-pink-400">
            Opss
          </span>{" "}
          qualcosa è andato storto...
            riprova
        </h1>
        {/* <p className="text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">
          Streetwear reinventato con t-shirt oversize audaci. Esprimi la tua unicità con stile e sicurezza.
        </p> */}
        <a href="/" className="mt-4 text-white hover:underline underline">
          Return Home →
        </a>
      </div>
      </div>
    );
  };
  
  export default Success;
