import React, { createContext, useState, useEffect, useCallback } from 'react';
import Cookies from 'js-cookie';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState([]);

    // Load cart from cookies on initial load
    useEffect(() => {
        const savedCart = Cookies.get('cart');
        if (savedCart) {
            setCart(JSON.parse(savedCart));
        }
    }, []);

    // Save cart to cookies whenever it changes
    useEffect(() => {
        Cookies.set('cart', JSON.stringify(cart), { expires: 7 }); // expires in 7 days
    }, [cart]);

    const addToCart = (product) => {
        setCart((prevCart) => {
          // Check if the product with the same id AND size already exists in the cart
          const existingItemIndex = prevCart.findIndex(
            (item) => item.id === product.id && item.size === product.size
          );
      
          if (existingItemIndex !== -1) {
            // If it exists, update the quantity correctly by adding the new quantity
            const updatedCart = [...prevCart];
            updatedCart[existingItemIndex].quantity = product.quantity; // Directly set the new quantity
            return updatedCart;
          } else {
            // If it's a new size, add it as a new item
            return [...prevCart, product];
          }
        });
      };
      
    
      const removeFromCart = (product) => {
        setCart((prevCart) => {
          // Filter out the item that matches the product id and size
          return prevCart.filter(
            (item) => !(item.id === product.id && item.size === product.size)
          );
        });
      };

      const clearCart = () => {
        setCart([]); // This should clear the cart
      };
      
      
      
    return (
        <CartContext.Provider value={{ cart, addToCart, removeFromCart, clearCart }}>
            {children}
        </CartContext.Provider>
    );
};
