import React, { useContext, useEffect, useState } from 'react';
import { CartContext } from './CartContext'; // Adjust this import to where your CartContext is located
import { Link } from 'react-router-dom';

const Success = () => {
  const { cart, clearCart } = useContext(CartContext);
  const [cartItems, setCartItems] = useState([]);
  const [hasClearedCart, setHasClearedCart] = useState(false);

  // Store cart items temporarily for rendering
  useEffect(() => {
    if (cart.length > 0 && !hasClearedCart) {
      setCartItems(cart);
    }
  }, [cart, hasClearedCart]);

  // Clear the cart after rendering
  useEffect(() => {
    if (!hasClearedCart && cart.length > 0) {
      clearCart();
      setHasClearedCart(true); // Set this to avoid clearing the cart again
    }
  }, [cart, clearCart, hasClearedCart]);

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-black text-white">
      <h1 className="text-3xl font-extrabold mb-4">
        <span className="text-transparent bg-clip-text bg-gradient-to-r from-sky-400 to-emerald-600">
          Shtrupopskii <span/>
        </span>
        marchio di abbigliamento
      </h1>
      <p className="text-lg mb-4 text-center">
        Grazie per il tuo acquisto! Il tuo ordine è stato effettuato con successo.
        <br/>
        Ti invieremo ulteriori informazioni via e-mail
      </p>
      

      <div className="bg-gray-800 p-6 rounded-lg shadow-md w-3/4 md:w-1/2">
        <h2 className="text-xl font-semibold mb-2">Order Details:</h2>
        <ul className="text-gray-300">
          {cartItems.length > 0 ? (
            cartItems.map((item) => (
              <li key={item.id + item.size} className="mb-1">
                {item.name} - Size: {item.size} - Quantity: {item.quantity} - Price: €{item.price}
              </li>
            ))
          ) : (
            <p>Your cart is empty.</p>
          )}
        </ul>
      </div>

      <Link to="/" className="mt-6 text-yellow-400 hover:text-yellow-600 underline">
        Return to Home
      </Link>
    </div>
  );
};

export default Success;
