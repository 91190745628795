/* eslint-disable react-hooks/rules-of-hooks */
// pages/products/[productName]/index.tsx
import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import { useState, useEffect, useMemo, useContext } from 'react';
import { CartContext } from '../CartContext';
import { products } from '../products'; // Import your products



const Shorts = () => {
  const product = products.find(p => p.name === "Shorts");
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedImage, setSelectedImage] = useState('/content/shorts1.png');

  // State for the selected images to be displayed
  const [selectedImages, setSelectedImages] = useState([1]);

  const [isInCart, setIsInCart] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const { cart, addToCart, removeFromCart } = useContext(CartContext);
  // Function to handle image selection
  const handleImageSelect = (image) => {
    setSelectedImages((prevImages) => {
      if (prevImages.includes(image)) {
        // Remove the image if it's already selected
        return prevImages.filter((img) => img !== image);
      } else {
        // Add the image if it's not selected
        return [...prevImages, image];
      }
    });
  };

  const selectedProduct = {
    images: [
      `${process.env.PUBLIC_URL}/content/shorts1.png`,
      `${process.env.PUBLIC_URL}/content/shorts2.png`,
    ],
  };

  
  
  useEffect(() => {
    const itemInCart = cart.find(
      (item) => item.id === product.id && item.size === selectedSize
    );
    if (itemInCart) {
      setIsInCart(true);
      setQuantity(itemInCart.quantity);
    } else {
      setIsInCart(false);
      setQuantity(1);
    }
  }, [cart, product.id, selectedSize]); // Add `selectedSize` to dependencies
  
  
  const handleAddToCart = () => {
    const existingItem = cart.find(
      (item) => item.id === product.id && item.size === selectedSize
    );
  
    if (existingItem) {
      // If product with the same size is already in the cart, update the quantity
      addToCart({ ...existingItem, quantity: existingItem.quantity + quantity });
    } else {
      // Add new product with selected size
      addToCart({ ...product, quantity, size: selectedSize });
    }
  
    setIsInCart(true);
  };
  
  
  
  const handleRemoveFromCart = () => {
    removeFromCart({ ...product, size: selectedSize });
    setIsInCart(false);
  };
  
  const handleQuantityChange = (change) => {
    setQuantity((prevQuantity) => {
      const newQuantity = Math.max(prevQuantity + change, 1);
      if (isInCart) {
        addToCart({ ...product, quantity: newQuantity, size: selectedSize });
      }
      return newQuantity;
    });
  };
  
  const [showMore, setShowMore] = useState(false);

  const handleMouseMove = (e) => {
    const img = e.target;
    const rect = img.getBoundingClientRect(); // Get image position and size
    const x = ((e.clientX - rect.left) / rect.width) * 100; // Calculate mouse X percentage
    const y = ((e.clientY - rect.top) / rect.height) * 100; // Calculate mouse Y percentage
  
    // Set the transform origin to the mouse position and zoom in
    img.style.transformOrigin = `${x}% ${y}%`;
    img.style.transform = 'scale(2)'; // Zoom level (you can increase or decrease)
  };
  
  const handleMouseLeave = (e) => {
    const img = e?.target; // Safely access target
    if (img) {
      // Reset zoom and transform origin
      img.style.transform = 'scale(1)';
      img.style.transition = 'transform 0.2s ease'; // Smooth transition on leave
    }
  };

  return (
        <div className='bg-black pb-20'>

        <nav className=" border-gray-200 sm:mb-20 mb-4"> {/* navbar */} 
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <Link to='../.' className="flex items-center space-x-3">
            <img src={`${process.env.PUBLIC_URL}/logo.png`}  className="h-8" alt="Flowbite Logo" />
            {/* <span className="self-center text-2xl font-semibold whitespace-nowrap">Shtrupopskii</span> */}
          </Link>
          <Link to='../checkout' className="flex md:order-2 space-x-3">
            <button
              type="button"
              className="text-black bg-[#dcff51] hover:bg-[#dcff51] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center"
            >
              Cart
            </button>
            
          </Link>
         
        </div>
      </nav>
      <div className="w-full flex justify-center mt-10">
      <div className="md:w-full w-[90%] md:flex grid justify-center ">
        <div className="grid text-white justify-center items-start md:w-2/6 w-full px-6 order-2 md:order-1 ">
            {/* Render the product name and description */}
            <div className="flex flex-col justify-start items-center mt-5">
              <div className=''>
              <div className='flex justify-between'>
                    <div className='text-white text-2xl font-bold'>Shorts</div>
                    <div className=' text-2xl font-bold text-[#dcff51] underline'>{product.price}</div>
                  </div>
                  <div className='text-white/90 text-md mt-5'>
                  Gli shorts cargo neri chic sono immediatamente accattivanti nell'ambiente urbano. Realizzati con cura in cotone pettinato di alta qualità da 320 GSM con un interno traspirante in French Terry, questi comodi shorts sono realizzati su misura per una vestibilità rilassata con dettagli di design che li distinguono dagli altri shorts cargo. Le tasche laterali a fisarmonica offrono funzionalità e un aspetto strutturato, mentre l'orlo consumato aggiunge un tocco robusto e audace che contrasta perfettamente con l'aspetto sbiadito sottile e sfumato degli shorts.
                                  <span className='block text-white/90 text-md mt-5'>
                                      <button onClick={() => setShowMore(!showMore)} className='text-[#dcff51] underline'>
                                          {showMore ? 'Read less' : 'Read more'}
                                      </button>
                                  </span>
                                  {showMore && (
                                      <div>
                                      Questo capo presenta una vita elastica con cordino, tasche laterali a fisarmonica e un orlo grezzo. Ha un effetto lavato, una vestibilità ampia e arriva appena sopra il ginocchio. È realizzato al 100% in cotone pettinato, in French Terry, ed è stato prelavato per mantenere la forma e le dimensioni. Il tessuto, lavato e facile da sbiadire, ha un peso di 320 g/m². La costruzione include una vita con topstitching a singolo passaggio, una finta patta e polsini con orlo grezzo a punto singolo.
                                      <br/>
                                      Istruzioni per la cura
                  <br/>
                  Lavare al rovescio in acqua fredda con un ciclo delicato e un detersivo delicato. Non candeggiare, immergere, strofinare o strizzare. Potrebbe verificarsi una leggera perdita di colore durante il primo lavaggio. È meglio lavare insieme indumenti dello stesso colore. Appendere ad asciugare ed evitare la luce solare diretta. Stirare, vaporizzare o asciugare in asciugatrice a bassa temperatura (max 30℃ o 90℉).
                  <br/>
                  Ulteriori informazioni
                  <br/>
                  Questo prodotto è stato lavato per un effetto sbiadito e potrebbe essere meno resistente al colore. Si consiglia il lavaggio a mano.
                                      </div>
                                  )}
                              </div>
                
              </div>
              
            </div>

            <div className='grid items-end h-full'>
                        <div className=''>
                          <div className="mt-5">
                            <label htmlFor="size" className="text-white/90 block">Select Size:</label>
                            <select
                              id="size"
                              className="border text-black rounded-lg p-2 w-full"
                              value={selectedSize}
                              onChange={(e) => setSelectedSize(e.target.value)}
                            >
                              <option value="">-- Select a size --</option>
                              {product.sizes.map((size) => (
                                <option key={size} value={size}>{size}</option>
                              ))}
                            </select>
                          </div>

                          <div className="w-full mt-auto sm:mt-4">
                            {isInCart ? (
                              <div>
                                <div className='flex items-center justify-center w-full p-3 rounded-xl bg-[#dcff51] text-black mb-2'>
                                  <button onClick={() => handleQuantityChange(-1)} className='px-2 py-1 border border-black'>-</button>
                                  <div className='px-4'>{quantity}</div>
                                  <button onClick={() => handleQuantityChange(1)} className='px-2 py-1 border border-black'>+</button>
                                  <button onClick={handleRemoveFromCart} className='px-4 py-1 border border-black ml-4'>Remove</button>
                                </div>
                                <Link to='../checkout' className="grid w-full">
                                  <button className="text-[#dcff51] border-2 border-[#dcff51] font-medium rounded-lg text-sm px-4 py-2 text-center">
                                    Go to Cart
                                  </button>
                                </Link>
                              </div>
                            ) : (
                              <button
  className={`flex justify-center w-full p-3 rounded-xl bg-[#dcff51] text-black ${!selectedSize ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
  onClick={handleAddToCart}
  disabled={!selectedSize}
>
  Add to Cart
</button>
                            )}
                          </div>
                          </div>
                        </div>

            
        {/* finish */}


        </div>
        <div className="grid gap-4 justify-center md:w-2/6 w-full order-1 md:order-2">
  <div className="image-container">
    {selectedImage && (
      <img
        className="product-image h-auto max-w-full rounded-lg"
        src={selectedImage}
        alt="Selected Product"
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      />
    )}
  </div>
      <div className="grid grid-cols-5 gap-4 items-end">
        {/* Render the product images */}
        {selectedProduct.images.map((image, index) => (
          <div
            key={index}
            onClick={() => {
              handleImageSelect(image);
              setSelectedImage(image); // Optional: set the selected image as the display image
            }}
            className='cursor-pointer'
          >
            <img
              className="h-auto max-w-full rounded-lg"
              src={image}
              alt={`Product ${index + 1}`}
            />
          </div>
        ))}
      </div>
    </div>
        
      </div>



      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg flex justify-center sm:mt-28 mt-16">
        <table className="md:w-4/6 w-[80%] text-sm text-left rtl:text-right text-gray-300 ">
            <thead className="text-xs text-gray-200 uppercase   ">
                <tr className=' border-b   '>
                    <th scope="col" className="px-6 py-3">
                        
                    </th>
                    
                    <th scope="col" className="px-6 py-3">
                        M
                    </th>
                    <th scope="col" className="px-6 py-3">
                        L
                    </th>
                    <th scope="col" className="px-6 py-3">
                        XL
                    </th>
                </tr>
            </thead>
            <tbody className='sm:text-sm text-[9px]'>
                <tr className="">
                    <th scope="row" className="px-6 py-4 font-medium text-white whitespace-nowrap ">
                    Outseam
                    </th>
                    <td className="px-6 py-4">
                    46	
                    </td>
                    <td className="px-6 py-4">
                    48 
                    </td>
                    <td className="px-6 py-4">
                    50
                    </td>
                   
                   
                    
                    
                </tr>
                <tr className=" ">
                <th scope="row" className="px-6 py-4 font-medium text-white whitespace-nowrap ">
                Waist
                    </th>
                    <td className="px-6 py-4">
                    76
                    </td>
                    <td className="px-6 py-4">
                    80
                    </td>
                    <td className="px-6 py-4">
                    84
                    </td>
                   
                    
                </tr>
                
                
            </tbody>
        </table>
    </div>
    </div>
   
  );
};

export default Shorts;