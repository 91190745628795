import React, { useContext, useState } from 'react';
import { CartContext } from './CartContext';
import { loadStripe } from '@stripe/stripe-js';
import { products } from './products'; // Import the products array
import { Link } from 'react-router-dom';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Checkout = () => {
  
  const [loading, setLoading] = useState(false); // Add loading state
  const [quantity, setQuantity] = useState(1);
  const { cart, addToCart, removeFromCart } = useContext(CartContext);


  const handleCheckout = async () => {
    setLoading(true); // Set loading to true when checkout starts

    try {
      const stripe = await stripePromise;
  
      const response = await fetch('https://shtrupopskii2.onrender.com/api/create-checkout-session', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          items: cart.map(item => ({
            price: item.id,
            quantity: item.quantity,
            size: item.size,
          })),
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to create checkout session');
      }
  
      const session = await response.json();
  
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });
  
      if (result.error) {
        alert(result.error.message);
      }
    } catch (error) {
      console.error('Checkout error:', error);
      alert('There was an error processing your checkout. Please try again.');
    } finally {
      setLoading(false); // Set loading to false when checkout process completes
    }
  };

  const getProductDetails = (id) => {
    return products.find(product => product.id === id) || {};
  };

  // const handleSizeChange = (item, newSize) => {
  //   addToCart({ ...item, size: newSize });
  // };
  
  const handleRemoveFromCart = (product) => {
    removeFromCart(product); // Pass the correct product with size
  };
  

  const handleQuantityChange = (product, change) => {
    addToCart({ ...product, quantity: Math.max(product.quantity + change, 1) });
  };

  // Add this function in your CartContext
  const calculateTotalPrice = () => {
    return cart.reduce((total, item) => total + parseFloat(item.price.replace('€', '')) * item.quantity, 0).toFixed(2);
  };


  return (
    <div className='w-full'>
      <nav className="border-gray-200 sm:mb-20 mb-4 justify-between"> {/* navbar */}
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <Link to='../.' className="flex items-center space-x-3">
            <img src={`${process.env.PUBLIC_URL}/logo.png`} className="h-8" alt="Flowbite Logo" />
          </Link>
          <Link to='../.' className="flex md:order-2 space-x-3">
            <button
              type="button"
              className="text-[#dcff51] outline-1 outline-[#dcff51] font-medium rounded-lg text-sm px-4 py-2 text-center"
            >
              Home
            </button>
          </Link>
        </div>
      </nav>
      <div className='text-white w-full flex flex-col justify-center items-center'>
        <div className='w-full sm:w-[50%] sm:p-0 p-4'>
          <h2 className='font-mono text-xl mb-10'>Your Cart:</h2>
          <ul>
          {cart.map((item, index) => {
  const product = getProductDetails(item.id);
  return (
    <div className='mb-8 border-b-2 border-[#dcff51]/50' key={`${item.id}-${item.size || index}`}>
      <Link to={`../products/${product.name}`} className="flex items-center space-x-4 justify-between cursor-pointer">
        <img src={product.image} alt={product.name} className="w-32 h-32 object-cover rounded" />
        <div className="text-end font-mono">
          <div>{product.name}</div>
          <div>Price: {product.price}</div>
          <div>Quantity: {item.quantity}</div>
          <div>Size: {item.size || 'Select a size'}</div>
        </div>
      </Link>
      <div className='flex items-center justify-between w-full my-3 rounded-xl  text-black mt-10'>
        <div className='flex items-center'>
          <button
            onClick={() => handleQuantityChange(item, -1)}
            className='px-2 py-1 border border-black bg-[#dcff51] hover:bg-[#bad745] active:bg-[#5e6d24] rounded-sm w-10'
          >
            -
          </button>
          <div className='px-4 text-[#dcff51]'>{item.quantity}</div>
          <button
            onClick={() => handleQuantityChange(item, 1)}
            className='px-2 py-1 border border-black bg-[#dcff51] hover:bg-[#bad745] active:bg-[#5e6d24] rounded-sm w-10'
          >
            +
          </button>
        </div>
        <button
          onClick={() => handleRemoveFromCart(item)}
          className='px-4 py-1 border border-black ml-4 bg-[#dcff51] hover:bg-[#bad745] active:bg-[#5e6d24] rounded-sm'
        >
          Remove
        </button>
      </div>
    </div>
  );
})}

          </ul>
          <div className="p-4 mb-4 border border-yellow-300 rounded-lg text-yellow-300" role="alert">
            <div className="flex items-center">
              <svg className="flex-shrink-0 w-4 h-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span className="sr-only">Info</span>
              <h3 className="text-lg font-bold">WARNING | ATTENZIONE</h3>
            </div>
            <div className="mt-2 mb-4 text-sm">
            La consegna richiede 6-10 giorni esclusi i tempi di produzione.
            </div>
          </div>

          {cart.length > 0 ? (
            loading ? (
              <div className='flex justify-center w-full p-3 rounded-xl bg-[#dcff51] text-black'>
                <div className='spinner'></div> {/* Add a spinner */}
              </div>
            ) : (
              <button className='flex justify-center w-full p-3 rounded-xl bg-[#dcff51] text-black cursor-pointer' onClick={handleCheckout}>Proceed to Checkout →<p className='text-bold ml-2'>€{calculateTotalPrice()}</p> </button>
            )
          ) : (
            <p>Your cart is empty.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Checkout;
