import React from 'react';
import ReactDOM from 'react-dom/client'; // Import createRoot
import './index.css';
import App from './App';
import { CartProvider } from './CartContext';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container); // Create a root
root.render(
  <CartProvider>
    <App />
  </CartProvider>
);
