import React from 'react';
import { Link } from 'react-router-dom';

const TermsAndPrivacy = () => {
  return (
    <div>
        <nav className="border-gray-200 sm:mb-20 mb-4 justify-between"> {/* navbar */}
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <Link to='../.' className="flex items-center space-x-3">
            <img src={`${process.env.PUBLIC_URL}/logo.png`} className="h-8" alt="Flowbite Logo" />
          </Link>
          <Link to='../.' className="flex md:order-2 space-x-3">
            <button
              type="button"
              className="text-[#dcff51] outline-1 outline-[#dcff51] font-medium rounded-lg text-sm px-4 py-2 text-center"
            >
              Home
            </button>
          </Link>
        </div>
      </nav>
    
    <div className="max-w-3xl mx-auto p-4 text-white">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
      <p className="mb-4">
        At Shtrupopskii, we value your privacy. This policy outlines how we collect, use, and protect your information:
      </p>
      <h2 className="text-2xl font-semibold mb-2">1. Information Collection:</h2>
      <p className="mb-4">
        We collect personal information only when you place an order, which is stored securely through Stripe. We do not share your personal information with any third parties.
      </p>
      <h2 className="text-2xl font-semibold mb-2">2. Data Protection:</h2>
      <p className="mb-4">
        Your payment information is processed through Stripe, and we do not store any sensitive payment data on our servers.
      </p>
      <h2 className="text-2xl font-semibold mb-2">3. Customer Rights:</h2>
      <p className="mb-4">
        You have the right to access, modify, or delete your personal information stored with us. For any inquiries regarding your data, please contact us.
      </p>

      <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
      <p className="mb-4">
        These Terms govern your use of the Shtrupopskii website and your purchase of our products:
      </p>
      <h2 className="text-2xl font-semibold mb-2">1. Product Design and Production:</h2>
      <p className="mb-4">
        We design all products, but production and shipping are handled by a third-party company in China.
      </p>
      <h2 className="text-2xl font-semibold mb-2">2. Production and Shipping Times:</h2>
      <p className="mb-4">
        Production typically takes 2-4 weeks, but this timeframe may vary. Shipping to Italy usually takes 6-10 days, excluding production time. We are not responsible for any delays caused by shipping carriers.
      </p>
      <h2 className="text-2xl font-semibold mb-2">3. Returns and Exchanges:</h2>
      <p className="mb-4">
        We do not accept returns. However, if an item is defective or lost during shipping, please contact us within 14 days after delivery to resolve the issue.
      </p>
      <h2 className="text-2xl font-semibold mb-2">4. Intellectual Property:</h2>
      <p className="mb-4">
        All designs and content on our website are owned by Shtrupopskii. Unauthorized use or reproduction is prohibited.
      </p>
      <h2 className="text-2xl font-semibold mb-2">5. Changes to Terms:</h2>
      <p className="mb-4">
        We reserve the right to update these Terms at any time. Any changes will be posted on our website.
      </p>
      <h2 className="text-2xl font-semibold mb-2">6. Governing Law:</h2>
      <p className="mb-4">
        These Terms are governed by the laws of Italy.
      </p>
      <p className="mb-4 text-xl mt-6">
        Contact us: shtrupopskii@gmail.com
      </p>
      
    </div>
    </div>
  );
};

export default TermsAndPrivacy;
